import { formatInTimeZone } from 'date-fns-tz'
import {
  Coaches,
  PlanMetadata,
  Timeslot,
  TrainingPlanStatus,
  TrainingPlanTrainer
} from '../../generated_types/training_plan'
import { TrainingPlanPath, UserPath } from '../../utils/api/paths'
import useScheduler from '../session_editor/scheduler_panel/use_scheduler'
import TimeslotChip from '../training_plan_trainers/timeslot_chip'
import { TimeslotClickedFunction } from '../types'

type Props = {
  plans: PlanMetadata[]
  coaches: Coaches
  coachAssignments: { [k: string]: TrainingPlanTrainer[] }
  className?: string
}

const SchedulingInfo = ({ plans, coachAssignments, coaches, className }: Props) => {
  const { onSchedulerDateChange, setSelectedTrainerId } = useScheduler()
  const onTimeslotClick: TimeslotClickedFunction = async ({ starts_at, timezone, coach_id }) => {
    onSchedulerDateChange({
      start_time: formatInTimeZone(starts_at, timezone, 'HH:mm'),
      start_date: formatInTimeZone(starts_at, timezone, 'yyyy-MM-dd')
    })
    if (coach_id) {
      setSelectedTrainerId(coach_id)
    }
  }

  return (
    <table className={`sw-table ${className ?? ''}`}>
      <thead>
        <tr>
          <th>Plan</th>
          <th>Timeslots</th>
          <th>Coaches</th>
        </tr>
      </thead>
      <tbody>
        {plans.map(plan => (
          <tr key={plan.id}>
            <td>
              <a href={`/plans/${plan.id}` satisfies TrainingPlanPath} rel="noreferrer" target="_blank">
                {plan.name || plan.id}
                {plan.draft ? ' (Draft)' : ''}
                {plan.status === TrainingPlanStatus.Pending ? ' (Pending)' : ''}
              </a>
            </td>
            <td>
              <div className="flex flex-row gap-2">
                {plan.timeslots.map(slot => (
                  <TimeslotChip
                    key={slot.id}
                    timeslot={slot}
                    onClick={(starts_at, timezone) => onTimeslotClick({ starts_at, timezone })}
                  />
                ))}
              </div>
            </td>
            <td>
              <CoachAssignments
                coaches={coaches}
                assignments={coachAssignments[plan.id] || []}
                timeslots={plan.timeslots}
                onTimeslotClick={onTimeslotClick}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

type CoachAssignmentProps = {
  coaches: Coaches
  assignments: TrainingPlanTrainer[]
  timeslots: Timeslot[]
  onTimeslotClick: TimeslotClickedFunction
}
const CoachAssignments = ({ coaches, assignments, timeslots, onTimeslotClick }: CoachAssignmentProps) => {
  return (
    <ul className="list-none">
      {assignments.map(assignment => (
        <li key={assignment.id} className="py-2 flex flex-row gap-2 items-center">
          <a href={`/users/${assignment.user_id}` satisfies UserPath} rel="noreferrer" target="_blank">
            {coaches[assignment.user_id]?.name || 'Unknown'}
          </a>

          {assignment.coach_timeslots.map(meta => (
            <TimeslotChipById
              key={meta.id}
              timeslot_id={meta.timeslot_id}
              timeslots={timeslots}
              onClick={(starts_at, timezone) => onTimeslotClick({ starts_at, timezone, coach_id: assignment.user_id })}
            />
          ))}
        </li>
      ))}
    </ul>
  )
}

export default SchedulingInfo

type TimeslotChipByIdProps = {
  timeslot_id: number
  timeslots: Timeslot[]
  onClick: (starts_at: Date, timezone: string) => void
}
const TimeslotChipById = ({ timeslot_id, timeslots, onClick }: TimeslotChipByIdProps) => {
  const slot = timeslots.find(slot => slot.id === timeslot_id)
  if (!slot) return null
  return <TimeslotChip timeslot={slot} onClick={onClick} />
}
