import { Field, Form, Formik } from 'formik'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import ErrorsToast from '../../library/errors_toast'
import Modal from '../../library/modal'
import SWFormGroup from '../../library/sw/sw_form_group'
import SWCheckbox from '../../library/sw/sw_checkbox'
import SWFieldWithLabel from '../../library/sw/sw_field_with_label'

import { PlanMetadata, TrainingPlanStatus } from '../types'
import { useApiSave } from '../../utils/json_request'
import { usePlanVersion } from './use_plan_version'
import SWHorizontalFieldWithLabel from '../../library/sw/sw_horizontal_field_with_label'

type Props = {
  data: PlanMetadata
  onUpdated: (data: PlanMetadata) => void
  onClose: () => void
  trainingPlanId: number
}

const PlanMetadataEditor = ({ data, onUpdated, onClose, trainingPlanId }: Props) => {
  const { lockVersions, planUpdated } = usePlanVersion()
  const { save, errors } = useApiSave(`/plans/${trainingPlanId}`, 'PATCH')
  const onSubmit = async (data: PlanMetadata) => {
    const result = await save({ payload: { training_plan: { ...data, lock_version: lockVersions[trainingPlanId] } } })
    if (result.ok) {
      const updatedPlan = result.data.training_plan
      planUpdated({ newVersion: updatedPlan.lock_version, planId: updatedPlan.id })
      onUpdated({
        name: updatedPlan.name,
        draft: updatedPlan.draft,
        status: updatedPlan.status
      })
      onClose()
    }
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={data}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Modal
            options={{ active: true, dismissOnClick: true }}
            title="Edit Plan"
            onClose={onClose}
            body={
              <>
                {Object.keys(errors).length > 0 && <ErrorsToast errors={errors} />}
                <SWFormGroup>
                  <SWFieldWithLabel name="name" value={values.name || ''} label="Name" className="sw-input" />
                </SWFormGroup>
                <SWFormGroup classNames="sw-snippet gap-0 mb-0">
                  <Field name="draft" type="checkbox" as={SWCheckbox} label="Draft" />
                  <p className="form-input-hint mb-4">Draft plans are not visible to managers or learners</p>
                  <SWHorizontalFieldWithLabel
                    name="status"
                    as="select"
                    className="sw-select grow"
                    label="Status"
                    onChange={e => setFieldValue('status', e.target.value)}
                  >
                    <option value={TrainingPlanStatus.Active}>Active</option>
                    <option value={TrainingPlanStatus.Pending}>Pending</option>
                    <option value={TrainingPlanStatus.Inactive}>Inactive</option>
                  </SWHorizontalFieldWithLabel>

                  {values.status === TrainingPlanStatus.Pending && (
                    <p className="form-input-hint mb-4">
                      Pending plans are visible to customers but not yet confirmed for scheduling
                    </p>
                  )}
                </SWFormGroup>
              </>
            }
            footer={
              <>
                <a onClick={onClose} className="sw-btn mr-auto">
                  <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                  Cancel
                </a>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
                >
                  Save
                  <FontAwesomeIcon icon={['far', 'floppy-disk']} />
                </button>
              </>
            }
          ></Modal>
        </Form>
      )}
    </Formik>
  )
}

export default PlanMetadataEditor
